
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import {
  CannedResponseData,
  CannedResponse,
} from '@/modules/withdrawValidation/withdrawValidation.types';
import { WithdrawValidationStore } from '@/modules/withdrawValidation/withdrawValidation.store';

@Component
export default class CannedResponseModal extends Vue {
  form!: WrappedFormUtils;
  isSaving = false;

  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function, required: true })
  close!: (arg: any) => void;

  @Prop({ required: true, default: 0 })
  eventForEdit!: number;

  getDefaultValue(key: keyof CannedResponse) {
    if (this.eventForEdit) {
      const event = WithdrawValidationStore.cannedResponses.find(
        (response: CannedResponse) => response.id === this.eventForEdit
      );
      if (!event) return '';
      return event[key];
    }
    return '';
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  handleClose(type: string) {
    this.close(type);
  }

  onSuccess(description: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  createCannedResponse(payload: CannedResponseData) {
    return WithdrawValidationStore.createCannedResponse(payload);
  }

  editCannedResponse(payload: CannedResponseData) {
    return WithdrawValidationStore.editCannedResponse({ payload, eventId: this.eventForEdit });
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as CannedResponseData;
    const save = this.eventForEdit ? this.editCannedResponse : this.createCannedResponse;
    const type = this.eventForEdit ? 'edit' : 'create';
    const successMessage = this.eventForEdit
      ? this.$t('withdrawValidation.cannedResponseEdited')
      : this.$t('withdrawValidation.cannedResponseCreated');
    const [err] = await to(save(formData));
    this.isSaving = false;
    if (err) return;
    this.onSuccess(successMessage as string);
    this.handleClose(type);
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.onSubmit();
    } else {
      this.close('cancel');
    }
  }
}
