var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"destroyOnClose":true,"confirm-loading":_vm.isSaving,"title":!!_vm.eventForEdit
      ? _vm.$t('withdrawValidation.editCannedResponse')
      : _vm.$t('withdrawValidation.createCannnedResponse'),"visible":_vm.visible},on:{"cancel":function($event){return _vm.handleClose('cancel')},"ok":_vm.onSubmit}},[_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.$t('withdrawValidation.cannedResponseTitle')}},[_c('a-input',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ close: ['esc'], save: ['enter'] }),expression:"{ close: ['esc'], save: ['enter'] }"},{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            initialValue: _vm.getDefaultValue('title'),
            rules: [
              {
                required: true,
                message: _vm.$t('common.requiredValidationError'),
              } ],
          } ]),expression:"[\n          'title',\n          {\n            initialValue: getDefaultValue('title'),\n            rules: [\n              {\n                required: true,\n                message: $t('common.requiredValidationError'),\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":!!_vm.eventForEdit},on:{"shortkey":_vm.keyAction}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('withdrawValidation.cannedResponse')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'body',
          {
            initialValue: _vm.getDefaultValue('body'),
          } ]),expression:"[\n          'body',\n          {\n            initialValue: getDefaultValue('body'),\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"autoSize":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }