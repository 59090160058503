import { ICellRendererParams } from '@ag-grid-community/core';
import dayjs from 'dayjs';
import {
  LONG_DATE_TIME,
  NO_VALUE,
  DATE,
  TIME,
  SHORT_DATE,
  TIME_SECONDS,
  EUROPEAN_DATE_TIME,
} from '@/constants';

type DateType = 'date' | 'time' | 'dateTime' | 'timeSeconds';

interface DateTimeCellParams extends ICellRendererParams {
  date_type?: DateType;
}

const DateTimeCell = ({ value, date_type }: DateTimeCellParams) => {
  if (!value) return '';
  const formatDateTime = dayjs.utc(value).format();
  const date = dayjs(formatDateTime);
  if (!date.isValid()) return NO_VALUE;
  if (date_type === 'date') return date.format(DATE);
  if (date_type === 'time') return date.format(TIME);
  if (date_type === 'timeSeconds') return date.format(TIME_SECONDS);
  if (date_type === 'dateTime') return date.format(SHORT_DATE);
  if (date_type === 'european_date_time') return date.format(EUROPEAN_DATE_TIME);
  return date.format(LONG_DATE_TIME);
};

export default DateTimeCell;
