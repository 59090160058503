
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TranslateResult } from 'vue-i18n';
import { to } from 'await-to-js';

import { WithdrawValidationStore } from '@/modules/withdrawValidation/withdrawValidation.store';

interface CannedResponseActionsParams extends ICellRendererParams {
  onClick?: (arg?: any) => any;
  removeEvent: (eventId?: number) => number;
  editEvent: (eventId: number) => number;
  clickFunc?: string;
}

@Component
export default class CannedResponseActions extends Vue {
  params: CannedResponseActionsParams | undefined;

  get hasData() {
    return this.params?.data;
  }

  get eventId() {
    if (!this.params?.data) return '';
    return this.params?.data.id;
  }

  errorMessage(description: TranslateResult = this.$t('genericError.description')) {
    return this.$notification.error({
      message: this.$t('common.error') as string,
      description: description as string,
    });
  }

  successMessage(description: TranslateResult) {
    return this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async editCannedResponse() {
    this.params?.editEvent(this.eventId);
  }

  async deleteCannedResponse() {
    const [err] = await to(WithdrawValidationStore.deleteCannedResponse(this.eventId));
    if (err) return;
    this.successMessage(this.$t('withdrawValidation.cannedResponseDeleted'));
    this.params?.removeEvent(this.eventId);
  }
}
