
import { Mixins, Component } from 'vue-property-decorator';
import { to } from 'await-to-js';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { WithdrawValidationStore } from '@/modules/withdrawValidation/withdrawValidation.store';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import CannedResponseModal from './CannedResponseModal.vue';
import CannedResponseActions from './components/CannedResponseActions.vue';
import { CannedResponse } from '@/modules/withdrawValidation/withdrawValidation.types';
import { AuthStore } from '@/modules/auth/auth.store';

@Component({ components: { CannedResponseModal, CannedResponseActions } })
export default class WithdrawSettingsPage extends Mixins(WithDataTable) {
  validationAmount = 0;
  isModalVisible = false;
  eventForEdit = 0;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('withdrawValidation.dateTime'),
          width: 180,
          field: 'created_at',
          cellRenderer: DateTimeCell,
        },
        {
          headerName: this.$t('withdrawValidation.cannedResponseName'),
          width: 200,
          field: 'title',
        },
        {
          headerName: this.$t('withdrawValidation.cannedResponse'),
          width: 300,
          field: 'body',
        },
        {
          headerName: this.$t('withdrawValidation.actions'),
          flex: 1,
          cellRendererFramework: 'CannedResponseActions',
          cellRendererParams: {
            removeEvent: this.removeCannedResponse,
            editEvent: this.editCannedResponse,
          },
        },
      ],
      rowData: this.tableData,
      cacheBlockSize: 15,
      rowBuffer: 15,
      pagination: true,
      paginationPageSize: 15,
    };
  }

  get gridOptions() {
    return {
      rowHeight: 50,
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return WithdrawValidationStore.cannedResponses;
  }

  get isBackofficeAdmin() {
    return AuthStore.isBackofficeAdmin;
  }

  removeCannedResponse(eventId: number) {
    this.gridApi?.applyTransaction({ remove: [{ id: eventId }] });
  }

  editCannedResponse(eventId: number) {
    this.eventForEdit = eventId;
    this.isModalVisible = true;
  }

  onSuccess(description: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  mounted() {
    this.fetchData();
    this.fetchValidationAmount();
  }

  async setValidationAmount() {
    const [err] = await to(WithdrawValidationStore.setValidationAmount(this.validationAmount));
    if (err) return;
    this.onSuccess(this.$t('withdrawValidation.amountSetSuccess') as string);
  }

  onCancel() {
    // no commment
  }

  addCannedResponse() {
    this.eventForEdit = 0;
    this.isModalVisible = true;
  }

  closeModal(type: string) {
    this.isModalVisible = false;
    if (type == 'create') {
      this.fetchData();
    }
    if (type == 'edit') {
      const updatedEvent = WithdrawValidationStore.cannedResponses.find(
        (response: CannedResponse) => response.id === this.eventForEdit
      );
      this.gridApi?.applyTransaction({ update: [updatedEvent] });
    }
  }

  setValidationAmountInitilaVal(data: any) {
    this.validationAmount = data.amount;
  }

  async fetchData(): Promise<void> {
    await WithdrawValidationStore.fetchCannedResponses();
  }

  async fetchValidationAmount() {
    const [err, res] = await to(WithdrawValidationStore.fetchValidationAmount());
    if (err) return Promise.reject(err);
    this.setValidationAmountInitilaVal(res);
  }
}
