export interface FetchWithdrawRequestsPayload {
  limit: number;
  offset: number;
  dateFrom?: string;
  dateTo?: string;
  type?: string;
  reservation_status?: string;
  username?: string;
}

export interface WithdrawRequest {
  id: number;
  platform_user_id: number;
  platform_username: string;
  amount: number;
  withdrew_amount: number | null;
  betting_place_id: number;
  tid: number | null;
  created_at: string;
  status: string;
  approved_by: string | null;
  canceled_by: number;
  canceled_by_type: string;
  code: string;
  withdrawal_type: string;
  canceled_note_id: number | null;
  betting_place_name: string | null;
  balance: number;
}

export interface WithdrawRequestMap {
  [key: string]: WithdrawRequest;
}

export interface TotalInOutData {
  system: string;
  totalWin: number;
  totalBet: number;
}

export interface TotalInOutPayload {
  limit?: number;
  offset?: number;
  dateFrom?: string;
  dateTo?: string;
  username?: string;
  status?: string;
  user_id: number;
}

export enum WithdrawalRequestStatus {
  PENDING = '__PENDING_VALIDATION__',
  CANCELED = '__CANCELED__',
  CLOSED = '__CLOSED__',
  EXPIRED = '__EXPIRED__',
  PROCESSING = '__PROCESSING__',
  ACTIVE = '__ACTIVE__',
}

export enum WithdrawalType {
  BANK = '__BANK__',
  CASH = '__CASH__',
}

export interface CannedResponseData {
  body: string;
  title: string;
}

export interface CannedResponse {
  body: string;
  created_at: string;
  id: number;
  title: string;
}

export interface TotalInOutData {
  system: string;
  total_in: number;
  total_out: number;
}
