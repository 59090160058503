import {
  CannedResponseData,
  FetchWithdrawRequestsPayload,
  TotalInOutPayload,
} from './withdrawValidation.types';
import { withdrawValidationRepo } from './withdrawValidation.repo';

class WithdrawValidationService {
  fetchWithdrawRequests(payload: FetchWithdrawRequestsPayload) {
    return withdrawValidationRepo.fetchWithdrawRequests(payload);
  }

  fetchTotalInOutTransactions(_payload: TotalInOutPayload) {
    return withdrawValidationRepo.fetchTotalInOutTransactions(_payload);
  }

  confirmWithdraw(eventId: number) {
    return withdrawValidationRepo.confirmWithdraw(eventId);
  }

  cancelWithdraw(payload: {
    eventId: number;
    formData: CannedResponseData;
    platformUserId: number;
  }) {
    return withdrawValidationRepo.cancelWithdraw(payload);
  }

  formatEventId(intKey: number | string) {
    return `wr_${intKey}`;
  }

  createCannedResponse(payload: CannedResponseData) {
    return withdrawValidationRepo.createCannedResponse(payload);
  }

  fetchCannedResponses() {
    return withdrawValidationRepo.fetchCannedResponses();
  }

  setValidationAmount(amount: number) {
    return withdrawValidationRepo.setValidationAmount(amount);
  }

  deleteCannedResponse(eventId: number) {
    return withdrawValidationRepo.deleteCannedResponse(eventId);
  }

  editCannedResponse({ payload, eventId }: { payload: CannedResponseData; eventId: number }) {
    return withdrawValidationRepo.editCannedResponse({ payload, eventId });
  }

  fetchValidationAmount() {
    return withdrawValidationRepo.fetchValidationAmount();
  }
}

export const withdrawValidationService = new WithdrawValidationService();
