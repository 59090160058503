import {
  CannedResponseData,
  FetchWithdrawRequestsPayload,
  TotalInOutPayload,
} from './withdrawValidation.types';
import { api } from '@/lib/api';

const fetchWithdrawRequests = (params: FetchWithdrawRequestsPayload) => {
  return api.get('/user/bo/withdrawal-reservation', {
    params,
    cache: {
      maxAge: 0,
    },
  });
};

const fetchTotalInOutTransactions = (params: TotalInOutPayload) => {
  return api.get('/payments/bo/transaction-sums', {
    params,
    cache: {
      maxAge: 0,
    },
  });
};

const confirmWithdraw = (eventId: number) => {
  return api.put(`/payments/lb/approve-withdrawal-reservation/${eventId}`);
};

const cancelWithdraw = (payload: {
  eventId: number;
  formData: CannedResponseData;
  platformUserId: number;
}) => {
  return api.put(`/payments/lb/bo/cancel-withdrawal-reservation/${payload.eventId}`, {
    ...payload.formData,
    platform_user_id: payload.platformUserId,
  });
};

const createCannedResponse = (payload: CannedResponseData) => {
  return api.post('/payments/lb/create-canceled-note', {
    ...payload,
  });
};

const fetchCannedResponses = () => {
  return api.get('/payments/lb/canceled-notes', {
    cache: {
      maxAge: 0,
    },
  });
};

const setValidationAmount = (amount: number) => {
  return api.put('payments/lb/withdraw-max-amount', {
    amount,
  });
};

const deleteCannedResponse = (eventId: number) => {
  return api.delete(`/payments/lb/delete-canceled-note/${eventId}`);
};

const editCannedResponse = ({
  payload,
  eventId,
}: {
  payload: CannedResponseData;
  eventId: number;
}) => {
  return api.put(`/payments/lb/update-canceled-note/${eventId}`, {
    ...payload,
  });
};

const fetchValidationAmount = () => {
  return api.get('/payments/bo/withdrawal-max-amount');
};

export const withdrawValidationRepo = {
  fetchWithdrawRequests,
  fetchTotalInOutTransactions,
  confirmWithdraw,
  cancelWithdraw,
  createCannedResponse,
  fetchCannedResponses,
  setValidationAmount,
  deleteCannedResponse,
  editCannedResponse,
  fetchValidationAmount,
};
