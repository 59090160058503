// import Vue from 'vue';
import Vue from 'vue';
import store from '@/store';
import { to } from 'await-to-js';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import {
  FetchWithdrawRequestsPayload,
  WithdrawRequest,
  WithdrawRequestMap,
  TotalInOutPayload,
  WithdrawalRequestStatus,
  CannedResponseData,
  CannedResponse,
  WithdrawalType,
  TotalInOutData,
} from './withdrawValidation.types';
import { withdrawValidationService } from './withdrawValidation.service';
import { objToArr } from '@/lib/objToArr';

const arrayToObject = (array: any[]): object => {
  return array.reduce((obj: any, item: any) => {
    obj[`wr_${item.id}`] = item;
    return obj;
  }, {});
};

@Module({ dynamic: true, store, name: 'withdrawValidation', namespaced: true })
export class WithdrawValidation extends VuexModule {
  validationRequests: WithdrawRequestMap = {};
  canceledWithdrawRequests: WithdrawRequestMap = {};
  totalInOut: any = [];
  cannedResponses: CannedResponse[] = [];
  validationRequestsTotal = 0;
  canceledWithdrawRequestsTotal = 0;
  lastUpdatedEvent = '';
  onWsEventAdded = '';

  get validationRequestsArray() {
    return objToArr(this.validationRequests);
  }

  get canceledWithdrawRequestsArray() {
    return objToArr(this.canceledWithdrawRequests);
  }

  @Mutation
  updateWithdrawalRequest({
    withdrawId,
    key,
    newValue,
  }: {
    withdrawId: number;
    key: keyof WithdrawRequest;
    newValue: any;
  }) {
    this.lastUpdatedEvent = '';
    const eventAccessor = withdrawValidationService.formatEventId(withdrawId);
    Vue.set(this.validationRequests[eventAccessor], key, newValue);
    this.lastUpdatedEvent = eventAccessor;
  }

  @Mutation
  addNewWithdrawalRequest(request: WithdrawRequest) {
    const eventAccessor = withdrawValidationService.formatEventId(request.id);
    Vue.set(this.validationRequests, eventAccessor, request);
    this.onWsEventAdded = eventAccessor;
  }

  // no need for map on canned-response-data beacuse it should not contain many items
  @Mutation
  updateCannedResponse({ payload, eventId }: { payload: CannedResponseData; eventId: number }) {
    const foundIndex = this.cannedResponses.findIndex(
      (response: CannedResponse) => response.id === eventId
    );
    if (foundIndex !== -1) {
      Vue.set(this.cannedResponses, foundIndex, {
        ...this.cannedResponses[foundIndex],
        ...payload,
      });
    }
  }

  @Mutation
  setWithdrawRequests({ items, total }: { items: WithdrawRequest[]; total: number }) {
    this.validationRequests = { ...this.validationRequests, ...arrayToObject(items) };
    this.validationRequestsTotal = total;
  }

  @Mutation
  setCanceledWithdrawRequests({ items, total }: { items: WithdrawRequest[]; total: number }) {
    this.canceledWithdrawRequests = { ...this.canceledWithdrawRequests, ...arrayToObject(items) };
    this.canceledWithdrawRequestsTotal = total;
  }

  @Mutation
  setTotalInOut(data: TotalInOutData) {
    this.totalInOut = data;
  }

  @Mutation
  clearStore() {
    this.validationRequests = {};
    this.canceledWithdrawRequests = {};
  }

  @Mutation
  setCannedResponses(data: CannedResponse[]) {
    this.cannedResponses = data;
  }

  @Action
  async fetchWithdrawRequests(payload: FetchWithdrawRequestsPayload): Promise<void> {
    const [err, res] = await to<any>(withdrawValidationService.fetchWithdrawRequests(payload));
    if (err) return Promise.reject(err);
    this.setWithdrawRequests(res);
  }

  @Action
  async fetchCanceledWithdrawRequests(payload: FetchWithdrawRequestsPayload): Promise<void> {
    const [err, res] = await to<any>(withdrawValidationService.fetchWithdrawRequests(payload));
    if (err) return Promise.reject(err);
    this.setCanceledWithdrawRequests(res);
  }

  @Action
  async fetchTotalInOutTransactions(payload: TotalInOutPayload) {
    const [err, res] = await to<any>(
      withdrawValidationService.fetchTotalInOutTransactions(payload)
    );
    if (err) return Promise.reject(err);
    this.setTotalInOut(res);
    return Promise.resolve(res);
  }

  @Action
  async confirmWithdraw(params: { eventId: number; withdrawalType: string }) {
    const [err] = await to<any>(withdrawValidationService.confirmWithdraw(params.eventId));
    if (err) return Promise.reject(err);
    this.updateWithdrawalRequest({
      withdrawId: params.eventId,
      key: 'status',
      newValue:
        WithdrawalType.CASH === params.withdrawalType
          ? WithdrawalRequestStatus.ACTIVE
          : WithdrawalRequestStatus.PROCESSING,
    });
  }

  @Action
  async cancelWithdraw(payload: {
    eventId: number;
    formData: CannedResponseData;
    platformUserId: number;
  }) {
    const [err] = await to<any>(withdrawValidationService.cancelWithdraw(payload));
    if (err) return Promise.reject(err);
    this.updateWithdrawalRequest({
      withdrawId: payload.eventId,
      key: 'status',
      newValue: WithdrawalRequestStatus.CANCELED,
    });
  }

  @Action
  async createCannedResponse(payload: CannedResponseData) {
    const [err] = await to<any>(withdrawValidationService.createCannedResponse(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async fetchCannedResponses() {
    const [err, res] = await to<any>(withdrawValidationService.fetchCannedResponses());
    if (err) return Promise.reject(err);
    this.setCannedResponses(res);
  }

  @Action
  async setValidationAmount(amount: number) {
    const [err] = await to<any>(withdrawValidationService.setValidationAmount(amount));
    if (err) return Promise.reject(err);
  }

  @Action
  async deleteCannedResponse(eventId: number) {
    const [err] = await to<any>(withdrawValidationService.deleteCannedResponse(eventId));
    if (err) return Promise.reject(err);
  }

  @Action
  async editCannedResponse({ payload, eventId }: { payload: CannedResponseData; eventId: number }) {
    const [err] = await to<any>(withdrawValidationService.editCannedResponse({ payload, eventId }));
    if (err) return Promise.reject(err);
    this.updateCannedResponse({ payload, eventId });
  }

  @Action
  fetchValidationAmount() {
    return withdrawValidationService.fetchValidationAmount();
  }

  @Action
  onNewWithdrawValidation(request: WithdrawRequest) {
    this.addNewWithdrawalRequest(request);
  }

  @Action
  updateWithdrawlReservation(data: { id: number; status: string }) {
    this.updateWithdrawalRequest({
      withdrawId: data.id,
      key: 'status',
      newValue: data.status,
    });
  }
}

export const WithdrawValidationStore = getModule(WithdrawValidation);
